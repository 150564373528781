import React from "react"
import {graphql, useStaticQuery} from "gatsby";

import LongFormSection from "../components/organizms/form-section/LongFormSection";
import Layout from "../components/general/layout/Layout";

const Form = () => {

    const data = useStaticQuery(graphql`
    {
      strapiFormPrices {
          form_dates {
            kalandtabor_dates {
              from_to_date
            }
            lovastabor_dates {
              from_to_date
            }
          }
          form_prices {
            szallas_atlag_price
            animator_price
            terulethasznalati_price
            medencehasznalati_price
            food_prices {
              price
              title
            }
            active_games {
              price
              title
            }
            group_programs {
              price
              title
            }
            handmade_programs {
              price
              title
            }
          }
      }
    }
  `)

    const {strapiFormPrices: {form_prices}} = data

    return (
        <Layout seo={{title: "Jelentkezés Form"}} isChild>
            <LongFormSection prices={form_prices}/>
        </Layout>
    );
}



export default Form
