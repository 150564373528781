import React, {useReducer} from 'react';
import {Checkbox, FormControlLabel, FormGroup, InputAdornment, InputLabel, Select} from "@mui/material";
import {navigate} from "gatsby";

import {FormReducer, initialState, sendLongFormData} from "../reducers/FormReducer";
import SectionLayoutGrid from "../../atoms/layout/SectionLayoutGrid";
import {Col} from "../../atoms/styled/layout/layoutComponents";
import {Body, Title} from "../../atoms/styled/typography/typographyComponents";
import {
    Buttonlink,
    Input,
    LongForm,
    SelectControl
} from "../styled/form-section/formsectionComponents";

const LongFormSection = ({prices}) => {

    const [state, dispatch] = useReducer(FormReducer, initialState)
    console.log(state)
    const campLength = ((new Date(state.last_date).getTime() - new Date(state.first_date).getTime()) / (1000 * 3600 * 24))
    const peopleNumber = Number(state.child_number) + Number(state.adults_number)
    const peopleNumberSleep = Number(state.adults_number) + Number(state.boys_number) + Number(state.girls_number)
    const childCount = Number(state.boys_number) + Number(state.girls_number)

    //Kedvezményes emberszám, amire az ottalvás esetén van szükség
    function CalculateAnimators(animators) {
        if (animators > Number(state.adults_number)) {
            return 0
        } else if (animators < Number(state.adults_number)) {
            return Number(state.adults_number) - animators
        } else if (animators === Number(state.adults_number)) {
            return 0
        }
    }

    const calcMaxWithAnimators = CalculateAnimators(Number(state.animator_number)) + Number(state.boys_number) + Number(state.girls_number)

    const priceSleep = (prices.szallas_atlag_price * peopleNumberSleep * campLength) +
        (Number(state.food_price) * peopleNumberSleep * campLength) +
        (Number(state.animator_number) * prices.animator_price * campLength) +
        (prices.terulethasznalati_price * peopleNumberSleep) +
        (Number(state.group_program_price)) +
        (Number(state.active_games_price) * childCount) +
        (Number(state.craftsman_price) * childCount);

    const priceDiscountSleep = (prices.szallas_atlag_price * calcMaxWithAnimators * campLength) +
        (Number(state.food_price) * calcMaxWithAnimators * campLength) +
        (Number(state.animator_number) * prices.animator_price * campLength) +
        (prices.terulethasznalati_price * calcMaxWithAnimators) +
        (Number(state.group_program_price)) +
        (Number(state.active_games_price) * childCount) +
        (Number(state.craftsman_price) * childCount);

    const price = (Number(state.food_price) * peopleNumber * campLength) +
        (Number(state.animator_number) * prices.animator_price * campLength) +
        (prices.terulethasznalati_price * peopleNumber * campLength) +
        (prices.medencehasznalati_price * peopleNumber * campLength) +
        (Number(state.group_program_price)) +
        (Number(state.active_games_price) * Number(state.child_number)) +
        (Number(state.craftsman_price) * Number(state.child_number))

    const priceOne = (Number(state.food_price) * peopleNumber) +
        (Number(state.animator_number) * prices.animator_price) +
        (prices.terulethasznalati_price * peopleNumber) +
        (prices.medencehasznalati_price * peopleNumber) +
        (Number(state.group_program_price)) +
        (Number(state.active_games_price) * Number(state.child_number)) +
        (Number(state.craftsman_price) * Number(state.child_number))

    return (
        <SectionLayoutGrid background={"brown"} customPadding={"firstElement"}>
            <Col>
                <Title sand={true} textAlign={"center"} marginBottom={"small"} weight={"bold"}>Ajánlatkérés</Title>
                <Body textAlign={"center"} weight={"bold"}>
                    Állítsa össze táborát és nézze meg, hogy kb. mennyibe kerülne.
                </Body>
                <Body textAlign={"center"} marginBottom={"small"} weight={"bold"}>
                    (Az ár csak tájékoztató jellegű)
                </Body>
            </Col>
            <Col>
                <LongForm onSubmit={(e) => {
                    e.preventDefault()
                    sendLongFormData('forms-organise-your-camps-with-uses', state, ((state.type === "one_day" && priceOne) || (state.type === "more_days" && price) || (state.type === "more_days_sleep" && priceDiscountSleep)), ((state.type === "one_day" && Math.round(priceOne / peopleNumber)) || (state.type === "more_days" && Math.round(price / peopleNumber)) || (state.type === "more_days_sleep" && Math.round(priceDiscountSleep / calcMaxWithAnimators)))).then(function (response) {
                        if (response.status === 200) {
                            navigate('/koszonjuk')
                        }
                    }).catch(function (error) {
                        alert(error.response.data.message)
                    })
                }}>
                    <Input
                        span={4}
                        size={"small"}
                        fullWidth
                        label={"Szervező neve:"}
                        required
                        name={"name"}
                        onChange={(e) => dispatch({
                            type: "SET_STATE",
                            value: e.target.value,
                            name: e.target.name
                        })}
                        value={state.name}
                    />
                    <Input
                        span={4}
                        size={"small"}
                        fullWidth
                        type="email"
                        label={"Email:"}
                        required
                        name={"email"}
                        onChange={(e) => dispatch({
                            type: "SET_STATE",
                            value: e.target.value,
                            name: e.target.name
                        })}
                        value={state.email}
                    />
                    <Input
                        span={4}
                        size={"small"}
                        fullWidth
                        label={"Telefonszám:"}
                        type="number"
                        required
                        name={"phone"}
                        onChange={(e) => dispatch({
                            type: "SET_STATE",
                            value: e.target.value,
                            name: e.target.name
                        })}
                        value={state.phone}
                    />
                    <SelectControl variant="outlined" size="small" required>
                        <InputLabel id="package-label">Válassza ki hogy milyen tábort szeretne szervezni:</InputLabel>
                        <Select
                            native
                            label="Válassza ki hogy milyen tábort szeretne szervezni:"
                            name={"type"}
                            onChange={(e) => dispatch({
                                type: "SET_STATE",
                                value: e.target.value,
                                name: e.target.name
                            })}
                            value={state.type}
                        >
                            <option aria-label="None" value=""/>
                            <option value="one_day">Egynapos</option>
                            <option value="more_days">Napközis (több napos tábor, minden nap hazamenetellel)</option>
                            <option value="more_days_sleep">Több nap ottalvással</option>
                        </Select>
                    </SelectControl>
                    {state.type === "one_day" &&
                    <>
                        <Input
                            span={4}
                            size={"small"}
                            fullWidth
                            label={"Tábor időpontja"}
                            required
                            InputLabelProps={{shrink: true}}
                            type="date"
                            name={"date"}
                            onChange={(e) => dispatch({
                                type: "SET_STATE",
                                value: e.target.value,
                                name: e.target.name
                            })}
                            value={state.date}
                        />
                        <Col span={6}/>
                    </>

                    }
                    {(state.type === "more_days" || state.type === "more_days_sleep") &&
                    <>
                        <Input
                            span={4}
                            size={"small"}
                            fullWidth
                            label={"Tábor első napja"}
                            required
                            InputLabelProps={{shrink: true}}
                            type="date"
                            name={"first_date"}
                            onChange={(e) => dispatch({
                                type: "SET_STATE",
                                value: e.target.value,
                                name: e.target.name
                            })}
                            value={state.first_date}
                        />
                        <Input
                            span={4}
                            size={"small"}
                            fullWidth
                            label={"Tábor utolsó napja"}
                            required
                            InputLabelProps={{shrink: true}}
                            type="date"
                            name={"last_date"}
                            onChange={(e) => dispatch({
                                type: "SET_STATE",
                                value: e.target.value,
                                name: e.target.name
                            })}
                            value={state.last_date}
                        />
                        <Col span={4}>
                            {(state.type === "more_days_sleep") ?
                                <Body marginTop={"smallest"}>Tábor hossza: {campLength ? campLength + 1 : 0} nap
                                    ({campLength ? campLength : 0} éjszaka)</Body> :
                                <Body marginTop={"smallest"}>Tábor hossza: {campLength ? campLength + 1 : 0} nap</Body>}
                        </Col>
                    </>

                    }
                    {(state.type === "more_days" || state.type === "one_day") &&
                    <>
                        <Input
                            span={4}
                            size={"small"}
                            fullWidth
                            label={"Felnőttek száma"}
                            required
                            InputProps={{
                                endAdornment: <InputAdornment position="end">fő</InputAdornment>,
                            }}
                            type="number"
                            name={"adults_number"}
                            onChange={(e) => dispatch({
                                type: "SET_STATE",
                                value: e.target.value,
                                name: e.target.name
                            })}
                            value={state.adults_number}
                        />
                        <Input
                            span={4}
                            size={"small"}
                            fullWidth
                            label={"Gyerekek száma"}
                            required
                            InputProps={{
                                endAdornment: <InputAdornment position="end">fő</InputAdornment>,
                            }}
                            type="number"
                            name={"child_number"}
                            onChange={(e) => dispatch({
                                type: "SET_STATE",
                                value: e.target.value,
                                name: e.target.name
                            })}
                            value={state.child_number}
                        />
                        <Col span={4}>
                            <Body marginTop={"smallest"}>Összesen: {peopleNumber ? peopleNumber : 0} fő</Body>
                        </Col>
                    </>
                    }
                    {
                        state.type === "more_days_sleep" &&
                        <>
                            <Col>
                                <Body marginTop={"small"}>Fiú és lány gyerekek száma azért fontos, hogy a szálláson a
                                    fiúk és a
                                    lányok
                                    külön szobákba/jurtákba kerüljenek.</Body>
                            </Col>
                            <Input
                                span={4}
                                size={"small"}
                                fullWidth
                                label={"Felnőttek száma"}
                                required
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">fő</InputAdornment>,
                                }}
                                type="number"
                                name={"adults_number"}
                                onChange={(e) => dispatch({
                                    type: "SET_STATE",
                                    value: e.target.value,
                                    name: e.target.name
                                })}
                                value={state.adults_number}
                            />
                            <Input
                                span={4}
                                size={"small"}
                                fullWidth
                                label={"Lány gyerekek száma"}
                                required
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">fő</InputAdornment>,
                                }}
                                type="number"
                                name={"girls_number"}
                                onChange={(e) => dispatch({
                                    type: "SET_STATE",
                                    value: e.target.value,
                                    name: e.target.name
                                })}
                                value={state.girls_number}
                            />
                            <Input
                                span={4}
                                size={"small"}
                                fullWidth
                                label={"Fiú gyerekek száma"}
                                required
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">fő</InputAdornment>,
                                }}
                                type="number"
                                name={"boys_number"}
                                onChange={(e) => dispatch({
                                    type: "SET_STATE",
                                    value: e.target.value,
                                    name: e.target.name
                                })}
                                value={state.boys_number}
                            />
                            <Col>
                                <Body>Összesen: {peopleNumberSleep ? peopleNumberSleep : 0} fő</Body>
                            </Col>
                        </>
                    }
                    {state.type &&
                    <>
                        <Input
                            span={4}
                            size={"small"}
                            fullWidth
                            label={"Legfiatalabb gyerek"}
                            required
                            InputProps={{
                                endAdornment: <InputAdornment position="end">év</InputAdornment>,
                            }}
                            type="number"
                            name={"youngest_child"}
                            onChange={(e) => dispatch({
                                type: "SET_STATE",
                                value: e.target.value,
                                name: e.target.name
                            })}
                            value={state.youngest_child}
                        />
                        <Input
                            span={4}
                            size={"small"}
                            fullWidth
                            label={"Legidősebb gyerek"}
                            required
                            InputProps={{
                                endAdornment: <InputAdornment position="end">év</InputAdornment>,
                            }}
                            type="number"
                            name={"oldest_child"}
                            onChange={(e) => dispatch({
                                type: "SET_STATE",
                                value: e.target.value,
                                name: e.target.name
                            })}
                            value={state.oldest_child}
                        />
                    </>
                    }
                    {state.type === "more_days_sleep" &&
                    <Col>
                        <Body weight={"bold"} marginTop={"small"}>A szállások pontos egyeztetése telefonon fog
                            megtörténni, a kalkulátor egy átlag árral számol a szálllásért!</Body>
                        <Body>Kérem válassza ki mely szállásokat preferálná :</Body>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={state.housing.jurta}
                                    name={"jurta"}
                                    onChange={(e) => dispatch({
                                        type: "SET_OBJECT_STATE",
                                        value: e.target.checked,
                                        name: e.target.name,
                                        object: "housing"
                                    })}
                                />
                            } label="Jurta 6x8 fő"/>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={state.housing.paraszthaz}
                                    name={"paraszthaz"}
                                    onChange={(e) => dispatch({
                                        type: "SET_OBJECT_STATE",
                                        value: e.target.checked,
                                        name: e.target.name,
                                        object: "housing"
                                    })}
                                />
                            } label="Parasztház 2x15 fő"/>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={state.housing.apartman}
                                    name={"apartman"}
                                    onChange={(e) => dispatch({
                                        type: "SET_OBJECT_STATE",
                                        value: e.target.checked,
                                        name: e.target.name,
                                        object: "housing"
                                    })}
                                />} label="Apartman 2db 2szobás apartman (szobánlént 2-2 emeletes ágy)"/>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={state.housing.tetoter}
                                    name={"tetoter"}
                                    onChange={(e) => dispatch({
                                        type: "SET_OBJECT_STATE",
                                        value: e.target.checked,
                                        name: e.target.name,
                                        object: "housing"
                                    })}
                                />
                            } label="Tetőtér 23fő"/>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={state.housing.metzger}
                                    name={"metzger"}
                                    onChange={(e) => dispatch({
                                        type: "SET_OBJECT_STATE",
                                        value: e.target.checked,
                                        name: e.target.name,
                                        object: "housing"
                                    })}
                                />} label="Metzger (Alsó 2x10fő) (Felső 10x4fő és 1x8fő)"/>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={state.housing.lovas}
                                    name={"lovas"}
                                    onChange={(e) => dispatch({
                                        type: "SET_OBJECT_STATE",
                                        value: e.target.checked,
                                        name: e.target.name,
                                        object: "housing"
                                    })}
                                />} label="Lovas házak 5fő/ház"/>
                        </FormGroup>
                    </Col>
                    }
                    {state.type &&
                    <>
                        <Col>
                            <Body marginTop={"small"}>Kérem válassza ki milyen étkezéseket kér:</Body>
                            <FormGroup>
                                {prices.food_prices.map((item, index) => (
                                    <FormControlLabel key={index} control={<Checkbox
                                        onChange={(e) => dispatch({
                                            type: "SET_PRICE",
                                            value: item.price,
                                            object: "food_price",
                                            checkedObject: "food_checked",
                                            name: item.title
                                        })}/>}
                                                      label={item.title}/>
                                ))}
                            </FormGroup>
                            <Body weight={"bold"} marginTop={"small"}>
                                Ételalergia esetén 20-30%-os felárat számítunk fel
                            </Body>
                        </Col>
                        <Input
                            span={3}
                            size={"small"}
                            fullWidth
                            label={"Ételérzékenyek száma"}
                            InputLabelProps={{shrink: true}}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">fő</InputAdornment>,
                            }}
                            type="number"
                            value={state.food_intolerant_number}
                            name={"food_intolerant_number"}
                            onChange={(e) => dispatch({
                                type: "SET_STATE",
                                value: e.target.value,
                                name: e.target.name
                            })}
                        />
                        <Input
                            span={9}
                            size={"small"}
                            fullWidth
                            label={"Ételérzékenységek"}
                            type="text"
                            value={state.food_intolerant_type}
                            name={"food_intolerant_type"}
                            onChange={(e) => dispatch({
                                type: "SET_STATE",
                                value: e.target.value,
                                name: e.target.name,
                            })}
                        />
                        <Col>
                            <Body marginTop={"small"} weight={"bold"}>Ottalvós tábor esetén ahány
                                animátort kérnek a csoport mellé, annyi felnőtt teljes ellátása és szállása ingyen
                                van!</Body>
                            <Body>
                                Az animátor vezeti és koordinálja a programokat, felügyel a biztonságra, igény esetén a
                                csapattal éjszakázik.(18 főnként 1 animátor ajánlott)
                            </Body>
                        </Col>
                        <Input
                            span={3}
                            size={"small"}
                            fullWidth
                            label={"Animátorok száma"}
                            required
                            InputLabelProps={{shrink: true}}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">fő</InputAdornment>,
                            }}
                            type="number"
                            name={"animator_number"}
                            onChange={(e) => dispatch({
                                type: "SET_STATE",
                                value: e.target.value,
                                name: e.target.name
                            })}
                            value={state.animator_number}
                        />
                    </>
                    }
                    {(state.type === "one_day" || state.type === "more_days") &&
                    <Col>
                        <Body>Medencehasználat:</Body>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={state.pool_use}
                                                                 name={"pool_use"}
                                                                 onChange={(e) => dispatch({
                                                                     type: "SET_STATE",
                                                                     value: e.target.checked,
                                                                     name: e.target.name
                                                                 })}
                            />} label="Pipáld be, ha szeretnél"/>
                        </FormGroup>
                    </Col>
                    }
                    {state.type &&
                    <>
                        <Col>
                            <Body marginTop={"small"} weight={"bold"}>Aktív és kézműves programjaink kizárólag képzett
                                animátoraink segítségével vehetők igénybe!</Body>
                            <Body>Szolgáltatások menüpont alatt pontosabb leírást kap programjainkról!</Body>

                        </Col>
                        <Col span={6}>
                            <Body>Válasszon aktív játékaink közül:</Body>
                            <FormGroup>
                                {prices.active_games.map((item, index) => (
                                    <FormControlLabel key={index} control={<Checkbox
                                        onChange={(e) => dispatch({
                                            type: "SET_PRICE",
                                            value: item.price,
                                            object: "active_games_price",
                                            checkedObject: "active_games_checked",
                                            name: item.title
                                        })}/>}
                                                      label={item.title}/>
                                ))}
                            </FormGroup>
                        </Col>
                        <Col span={6}>
                            <Body>Válasszon kézműves programjaink közül:</Body>
                            <FormGroup>
                                {prices.handmade_programs.map((item, index) => (
                                    <FormControlLabel key={index} control={<Checkbox
                                        onChange={(e) => dispatch({
                                            type: "SET_PRICE",
                                            value: item.price,
                                            object: "craftsman_price",
                                            checkedObject: "craftsman_checked",
                                            name: item.title
                                        })}/>}
                                                      label={item.title}/>
                                ))}
                            </FormGroup>
                        </Col>
                        <Col>
                            <Body>Válasszon csoportos programjaink közül (animátort nem igényelnek):</Body>
                            <FormGroup>
                                {prices.group_programs.map((item, index) => (
                                    <FormControlLabel key={index} control={<Checkbox
                                        onChange={(e) => dispatch({
                                            type: "SET_PRICE",
                                            value: item.price,
                                            object: "group_program_price",
                                            checkedObject: "group_program_checked",
                                            name: item.title
                                        })}/>}
                                                      label={item.title}/>
                                ))}
                            </FormGroup>
                        </Col>
                        <Col>
                            <Body>Terembérlés:</Body>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={state.teremberles}
                                                                     name={"teremberles"}
                                                                     onChange={(e) => dispatch({
                                                                         type: "SET_STATE",
                                                                         value: e.target.checked,
                                                                         name: e.target.name
                                                                     })}
                                />} label="Pipáld be, ha szeretnél"/>
                            </FormGroup>

                        </Col>
                        <Col span={4}>
                            <Input
                                size={"small"}
                                fullWidth
                                multiline
                                rows={4}
                                label={"Megjegyzés a terembérléshez"}
                                name={"teremberles_megjegyzes"}
                                onChange={(e) => dispatch({
                                    type: "SET_STATE",
                                    value: e.target.value,
                                    name: e.target.name
                                })}
                                value={state.teremberles_megjegyzes}
                            />
                        </Col>
                        <Col>
                            <Body weight={"bold"}>Kérjük ide írja az egyéb megjegyzéseit:</Body>
                        </Col>
                        <Col span={4}>
                            <Input
                                size={"small"}
                                fullWidth
                                multiline
                                rows={4}
                                label={"Megjegyzés az ajánlatkéréshez"}
                                name={"megjegyzes"}
                                onChange={(e) => dispatch({
                                    type: "SET_STATE",
                                    value: e.target.value,
                                    name: e.target.name
                                })}
                                value={state.megjegyzes}
                            />
                        </Col>
                        {(state.type === "more_days_sleep") &&
                        <Col>
                            <Body>Összesen: <strong>{priceSleep ? priceSleep : "Nincs elég megadott adat"}</strong> ft</Body>
                            <Body>Összesen (Animátor kedvezménnyel
                                együtt): <strong>{priceDiscountSleep ? priceDiscountSleep : "Nincs elég megadott adat"}</strong> ft</Body>
                            <Body>Egy főre
                                jutó: <strong>{priceSleep ? Math.round(priceDiscountSleep / calcMaxWithAnimators) : "Nincs elég megadott adat"}</strong> ft</Body>
                        </Col>
                        }
                        {(state.type === "more_days") &&
                        <Col>
                            <Body>Összesen: <strong>{price ? price : "Nincs elég megadott adat"}</strong> ft</Body>
                            <Body>Egy főre
                                jutó: <strong>{(price && peopleNumber !== 0) ? Math.round(price / peopleNumber) : "Nincs elég megadott adat"}</strong> ft</Body>
                        </Col>
                        }
                        {(state.type === "one_day") &&
                        <Col>
                            <Body>Összesen: <strong>{priceOne ? priceOne : "Nincs elég megadott adat"}</strong> ft</Body>
                            <Body>Egy főre
                                jutó: <strong>{(priceOne && peopleNumber !== 0) ? Math.round(priceOne / peopleNumber) : "Nincs elég megadott adat"}</strong> ft</Body>
                        </Col>
                        }
                    </>}
                    <Col grid justifyContent="center">
                        <Buttonlink variant={"yellow"} type={"submit"}>Küldés</Buttonlink>
                    </Col>
                </LongForm>
            </Col>
        </SectionLayoutGrid>

    );
};

export default LongFormSection;
